const getDefaultToggle = id => {
  if (window.env && window.env.toggles) {
    const { toggles } = window.env;
    return toggles[id]?.enabled || false;
  }

  return false;
};

export const FEATURE_TOGGLE = {
  MAINTENANCE_BANNER: "maintenance-banner",
  STOP_SELL_SURVEY: "stop-sell-survey",
  GENIUS: "genius",
  RATES_BREAKDOWN: "rates-breakdown",
  REPORTING_LOCATIONS: "reporting-locations"
};

export const FT_POLL_INTERVAL = 60000;
export const FT_DEFAULTS = Object.keys(FEATURE_TOGGLE).reduce(
  (prev, toggle) => ({ ...prev, [FEATURE_TOGGLE[toggle]]: { enabled: getDefaultToggle(FEATURE_TOGGLE[toggle]) } }),
  {}
);
