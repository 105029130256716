import React, { Suspense, lazy, useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import { Shell } from "../layouts";
import RequireAuth from "../containers/RequireAuth";
import NotAuth from "../containers/NotAuth";
import { recordPageView } from "../aws/rum";
import { FEATURE_TOGGLE, PATHS } from "../consts";
import NoMatch from "./NoMatch/NoMatch";
import useGAEvent from "../hooks/useGAEvent";
import useFeatureToggle from "../hooks/useFeatureToggle";

const Login = lazy(() => import("./Login/Login"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const FirstTimeLogin = lazy(() => import("./FirstTimeLogin"));
const Bookings = lazy(() => import("./Bookings/Bookings"));
const ManageDrivers = lazy(() => import("./Drivers/ManageDrivers"));
const Policy = lazy(() => import("./Policy"));
const ErrorPage = lazy(() => import("./Error/Error"));
const Reports = lazy(() => import("./Reports/Reports"));
const Rates = lazy(() => import("./Rates/Rates"));
const Location = lazy(() => import("./Locations/Location"));
const StopSales = lazy(() => import("./StopSells/StopSaleRouter"));
const Campaigns = lazy(() => import("./Campaigns/CampaignsRouter"));
const Genius = lazy(() => import("./Genius/GeniusRouter"));
const LandingPage = lazy(() => import("./LandingPage"));
const LeadsThankYou = lazy(() => import("./Leads/LeadsThankYou"));
const LeadsFormPage = lazy(() => import("./Leads/LeadsFormPage"));

const removePathIds = (path: string) => {
  return path
    .split("/")
    .map((part, index) => {
      if (index === 0 || isNaN(Number(part))) return part;
      return ":id";
    })
    .join("/");
};

const RootRouter = () => {
  const location = useLocation();
  const { sendEvent } = useGAEvent();

  const isGeniusEnabled = useFeatureToggle(FEATURE_TOGGLE.GENIUS);

  useEffect(() => {
    sendEvent("page_view");
    const routePath = removePathIds(location.pathname);
    recordPageView(routePath);
  }, [location]);

  return (
    <Shell>
      <Suspense fallback={null}>
        <Routes>
          <Route
            path={PATHS.LOGIN}
            element={
              <NotAuth>
                <Login />
              </NotAuth>
            }
          />
          <Route
            path={PATHS.RESET_PASSWORD}
            element={
              <NotAuth>
                <ResetPassword />
              </NotAuth>
            }
          />
          <Route
            path={PATHS.FIRST_TIME_LOGIN}
            element={
              <NotAuth>
                <FirstTimeLogin />
              </NotAuth>
            }
          />
          <Route
            path={`${PATHS.BOOKINGS}/*`}
            element={
              <RequireAuth>
                <Bookings />
              </RequireAuth>
            }
          />
          <Route
            path={PATHS.DRIVERS}
            element={
              <RequireAuth>
                <ManageDrivers />
              </RequireAuth>
            }
          />
          <Route
            path={`${PATHS.AVAILABILITY_DASHBOARD}/*`}
            element={
              <RequireAuth>
                <StopSales />
              </RequireAuth>
            }
          />
          <Route
            path={`${PATHS.CAMPAIGNS}/*`}
            element={
              <RequireAuth>
                <Campaigns />
              </RequireAuth>
            }
          />
          <Route
            path={`${PATHS.GENIUS}/*`}
            element={<RequireAuth>{isGeniusEnabled ? <Genius /> : <NoMatch />}</RequireAuth>}
          />
          <Route
            path={`${PATHS.REPORTS}/*`}
            element={
              <RequireAuth>
                <Reports />
              </RequireAuth>
            }
          />
          <Route
            path={`${PATHS.POLICY}/*`}
            element={
              <RequireAuth>
                <Policy />
              </RequireAuth>
            }
          />
          <Route
            path={`${PATHS.RATES}/:supplierLocationId`}
            element={
              <RequireAuth>
                <Location />
              </RequireAuth>
            }
          />
          <Route
            path={`${PATHS.RATES}/*`}
            element={
              <RequireAuth>
                <Rates />
              </RequireAuth>
            }
          />
          <Route path={PATHS.LANDING_PAGE_THANK_YOU} element={<LeadsThankYou />} />
          <Route path={PATHS.LANDING_PAGE} element={<LandingPage />} />
          <Route path={PATHS.LEADS_FORM_PAGE} element={<LeadsFormPage />} />
          <Route path={PATHS.ERROR} element={<ErrorPage />} />
          <Route
            path="*"
            element={
              <RequireAuth>
                <NoMatch />
              </RequireAuth>
            }
          />
        </Routes>
      </Suspense>
    </Shell>
  );
};

export default RootRouter;
