import React from "react";
import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import { BUIProvider } from "@bookingcom/bui-react";
import TravellerTheme from "@bookingcom/bui-react/themes/traveller";
import { SWRConfig } from "swr";
import { BrowserRouter } from "react-router-dom";

import { location, translations } from "./i18n";
import { FT_POLL_INTERVAL, FT_DEFAULTS } from "./consts";
import ErrorBoundary from "./containers/ErrorBoundary";
import RootRouter from "./routes";
import { FeatureTogglesProvider, NotificationProvider, SessionContextProvider, SupplierProvider } from "./context";
import "./aws/amplify/config";
import "./index.scss";
import "./utils/yupSchemaMethods";

const urlParams = new URLSearchParams(window.location.search);
const themeMode = urlParams.get("themeMode") || "light";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        dedupingInterval: 100
      }}>
      <BrowserRouter>
        <FeatureTogglesProvider pollInterval={FT_POLL_INTERVAL} defaultToggles={FT_DEFAULTS}>
          <NotificationProvider>
            <SessionContextProvider>
              <SupplierProvider>
                <IntlProvider locale={location} messages={translations}>
                  <BUIProvider theme={TravellerTheme} defaultThemeMode={themeMode}>
                    <RootRouter />
                  </BUIProvider>
                </IntlProvider>
              </SupplierProvider>
            </SessionContextProvider>
          </NotificationProvider>
        </FeatureTogglesProvider>
      </BrowserRouter>
    </SWRConfig>
  </ErrorBoundary>
);
