const PATHS = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  FIRST_TIME_LOGIN: "/first-time-login",
  BOOKINGS: "/bookings",
  BOOKINGS_RIDES: "/bookings/rides",
  BOOKINGS_PAST_RIDES: "/bookings/past-rides",
  DRIVERS: "/drivers",
  POLICY: "/policy",
  COOKIES: "/policy/cookies",
  PRIVACY: "/policy/privacy",
  ERROR: "/error",
  REPORTS: "/reports",
  REPORTS_DRIVERS: "/reports/drivers",
  REPORTS_RIDES: "/reports/rides",
  REPORTS_OVERVIEW: "/reports/overview",
  REPORTS_PERFORMANCE: "/reports/performance",
  REPORTS_PERFORMANCE_LOCATION: "/reports/performance/location",
  REPORTS_PERFORMANCE_DRIVERS: "/reports/performance/drivers",
  REPORT_REPORTING_PERFORMANCE: "/reports/performance/reporting-location",
  REPORTS_DRIVERS_RIDES: "/reports/drivers/rides",
  RATES: "/locations",
  BASE_RATES_ADD: "/locations/base-rates/add",
  BASE_RATES_EDIT: "/locations/base-rates/edit",
  AVAILABILITY_DASHBOARD: "/availability",
  AVAILABILITY_ADD: "/availability/add",
  LANDING_PAGE: "/become-a-partner",
  LANDING_PAGE_THANK_YOU: "/become-a-partner/thank-you",
  LEADS_FORM_PAGE: "/become-a-partner/join",
  RATES_SETUP_HOURLY: "/locations/rate-rules/hourly",
  RATES_SETUP_DAILY: "/locations/rate-rules/daily",
  CAMPAIGNS: "/campaigns",
  CAMPAIGNS_ADD: "/campaigns/add",
  AREA_OF_OPERATION_ADD: "/locations/area-of-operation/add",
  AREA_OF_OPERATION_EDIT: "/locations/area-of-operation/edit",
  CONTACT_DETAILS_EDIT: "/locations/contact-details/edit",
  EXTRAS_EDIT: "/locations/extras/edit",
  SELECT_FIXED_ROUTES_AIRPORT: "/locations/fixed-routes/airport",
  MANAGE_FIXED_ROUTES: "/locations/fixed-routes/manage",
  OPERATING_HOURS: "/locations/pickup-times/manage",
  AIRPORTS_MANAGE: "/locations/airports/manage",
  AIRPORTS_MANAGE_ADD: "/locations/airports/manage/add",
  VEHICLES_MANAGE: "/locations/vehicles/manage",
  PICKUP_INSTRUCTIONS_AIRPORTS: "/locations/pickup-instructions/airports",
  PICKUP_INSTRUCTIONS_EDIT: "/locations/pickup-instructions/airports/edit",
  PICKUP_INSTRUCTIONS_ADD: "/locations/pickup-instructions/airports/add",
  GENIUS: "/genius"
} as const;

export default PATHS;
